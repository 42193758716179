<template>
  <div class="product-mobile">
    <HeadMobile carouseType="PRODUCT"></HeadMobile>
    <div class="product-titles">
      <p class="product-title">{{ $store.state.staticName.product }}</p>
    </div>
    <div class="menu-scoll">
      <div
        class="menu-scoll-item"
        v-for="(item, i) in fenLe"
        :class="item.id === fufenleId ? 'active' : ''"
        :key="'nav' + i"
        @click="toFuFenle(item.id)"
      >
        <span class="menu-scoll-item-title">{{ item.name }}</span>
        <!-- <span class="menu-scoll-item-doc">钛制品</span> -->
      </div>
      <div class="padding-box">1</div>
    </div>
    <div class="menu-sub-scoll">
      <div
        class="menu-sub-scoll-item"
        v-for="(item, i) in subFenLe"
        :class="item.id === fenleId ? 'active1' : ''"
        :key="'sub' + i"
        @click="toFenle(item.id)"
      >
        {{ item.name }}
      </div>
      <!-- <div style="color: #000; font-size: 14px">11111111111111</div> -->
    </div>
    <div class="product-list" v-if="show">
      <div
        class="product-list-item"
        v-for="(item, i) in shopList"
        @click="toDetail(item.id)"
        :key="'i' + i"
      >
        <div class="product-list-item-img">
          <img class="product-list-item-img" :src="item.tradeBanner" />
        </div>
        <div class="product-list-item-title">{{ item.tradeName }}</div>
      </div>
    </div>
    <new-pagination
      v-if="show"
      @current-change="currentChange"
      :page-size="page.pageSize"
      layout="prev, pager, next"
      :total="total"
    ></new-pagination>
    <div class="product-main-box-right" v-if="!show" v-loading="loading">
      <div class="product-xiangqin-h">
        <div class="product-xiangqin">
          <div class="img-box">
            <img class="img-box" :src="imgUrl" />
          </div>
          <div class="xiangqin-right">
            <i @click="close" class="close el-icon-circle-close"></i>
            <div class="xiangqin-header">
              <div class="xiangqin-name">{{ detail.tradeName }}</div>

              <div class="xiangqin-jieshao">{{ detail.describe }}</div>
            </div>
            <div class="xiangqin-bottom">
              <div class="xiangqin-bottom-left">
                <div class="xiangqin-qian">
                  <span class="icon">￥</span>{{ childInfo.tradePrice }}
                </div>
                <!--                <div class="xiangqin-lianxi">{{ $store.state.staticName.ContactHotline }}：0812-555555</div>-->
                <!--                <div class="xiangqin-lianxi">{{ $store.state.staticName.Contactservice }}QQ：2248767</div>-->
              </div>
              <div class="xiangqin-bottom-right">
                <div class="buy-img">
                  <img
                    class="buy-img"
                    :src="`${this.baseUrl}/createLogoQRCode?id=${
                      detail.id ? detail.id : $route.query.i
                    }`"
                  />
                </div>
                <div class="buy-text">
                  {{ $store.state.staticName.Clickscan }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-xiangqin-size">
        <div
          v-for="(it, i) in detail.children"
          :key="'q' + i"
          :class="active === it.id ? 'active' : ''"
          @click="updateImg(i)"
        >
          {{ it.tradeName }}
        </div>
      </div>
      <div class="detail-info-box" v-if="!show">
        <div class="introduce-box">
          <div class="title">
            {{ $store.state.staticName.Productintroduction }}:
          </div>
          <div class="detail-text">
            {{ childInfo.introduce }}
          </div>
        </div>

        <!-- <div class="introduce-box">
          <div class="title">
            {{ $store.state.staticName.Customizedcustomers }}:
          </div>
          <div class="detail-text">
            {{ childInfo.custom }}
          </div>
        </div>

        <div class="introduce-box">
          <div class="title">{{ $store.state.staticName.Usepurpose }}:</div>
          <div class="detail-text">
            {{ childInfo.purpose }}
          </div>
        </div>

        <div class="introduce-box">
          <div class="title">{{ $store.state.staticName.Productsize }}:</div>
          <div class="detail-text">
            {{ childInfo.size }}
          </div>
        </div>

        <div class="introduce-box">
          <div class="title">
            {{ $store.state.staticName.Productmaterial }}:
          </div>
          <div class="detail-text">
            {{ childInfo.texture }}
          </div>
        </div>

        <div class="introduce-box">
          <div class="title">{{ $store.state.staticName.Productprocess }}:</div>
          <div class="detail-text">
            {{ childInfo.craft }}
          </div>
        </div> -->
      </div>
    </div>
    <FootMobile></FootMobile>
  </div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import FootMobile from "@/components/mobile/FootMobile";
import { mapState } from "vuex";
export default {
  name: "ProductMobile.vue",
  components: {
    FootMobile,
    HeadMobile,
  },
  data() {
    return {
      active: 0,
      imgUrl: "",
      detail: {},
      childInfo: {},
      fenleId: 0,
      fufenleId: 0,
      loading: false,
      shopList: [],
      show: true,
      fenLe: [],
      subFenLe: [],
      total: 0,
      page: {
        currentPage: 1,
        pageSize: 15,
        input: 0,
      },
    };
  },
  watch: {
    "$route.query.c": {
      handler(newVal) {
        this.reFresh();
        // this.fenleId = newVal
      },
    },
    "$store.state.languageType": {
      handler(newVal) {
        let this1 = this;
        this.loading = true;
        this.getFenle();
        let fenleId = this.fenleId;
        if (this.detailId && this.showOne) {
          this.toDetail(this.detailId);

          let t = setTimeout(() => {
            this1.loading = false;
            this1.show = false;
            clearTimeout(t);
          }, 80);
        }

        let t = setTimeout(() => {
          this1.fenleId = fenleId;
          this1.loading = false;
          this1.toFenle(fenleId);
          clearTimeout(t);
        }, 50);
      },
      // deep: true // 深度监听
    },
  },
  created() {
    this.reFresh();
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  methods: {
    updateImg(i) {
      this.childInfo = this.detail.children[i];
      this.active = this.childInfo.id;
      this.imgUrl = this.childInfo.tradePreview[0];
    },
    reFresh() {
      let classify = this.$route.query.c;
      let product = this.$route.query.i;
      // 赋值当前分类

      this.getFenle(classify);
      // 查询详情
      if (product) {
        let this1 = this;
        let t = setTimeout(() => {
          this1.show = false;
          clearTimeout(t);
        }, 150);

        this.toDetail(product);
      }
    },
    close() {
      this.show = true;
    },
    toDetail(id) {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "/queryCommodityOne",
        params: {
          id: id,
          type: this.languageType,
        },
      })
        .then((res) => {
          if (res.data.status == "200") {
            this.show = false;
            this.detail = res.data.value;
            if (res.data.value.children.length > 0) {
              this.active = res.data.value.children[0].id;
              this.imgUrl = res.data.value.children[0].tradePreview[0];
              this.childInfo = res.data.value.children[0];
            } else {
              this.imgUrl = res.data.value.tradeBanner[0];
              this.childInfo = {};
            }

            this.loading = false;
          } else {
            this.$message({
              message: res.data.message,
              type: "warning",
              duration: 4000,
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getChildClassify(i) {
      this.fenLe.some((item) => {
        if (item.id === i) {
          this.subFenLe = item.children;
          return true;
        }
      });
    },
    getActive(classify) {
      this.fenLe.some((item) => {
        item.children.some((child) => {
          if (child.id === classify) {
            this.fufenleId = item.id;
            this.fenleId = child.id;
            this.subFenLe = item.children;
            return true;
          }
        });
      });
    },
    toFuFenle(i) {
      this.fufenleId = i;
      this.getChildClassify(i);
      this.fenleId = this.subFenLe[0].id;
      this.toFenle(this.fenleId);
    },
    toFenle(i) {
      this.fenleId = i;
      if (isNaN(i)) return;
      this.getChildClassify(i);
      this.show = true;
      this.page.input = i;
      this.$axios({
        method: "GET",
        url: "/queryCommodityByClassify",
        params: {
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          input: i,
          type: this.languageType,
        },
      }).then((res) => {
        // this.fenLe = res.data.value ? res.data.value : 0;
        this.shopList = res.data.value.value;
        this.total = res.data.value.total;
      });
    },
    getFenle(classify) {
      this.$axios({
        method: "GET",
        url: "/queryClassifyByMenu",
        params: {
          type: this.languageType,
        },
      }).then((res) => {
        this.fenLe = res.data.value ? res.data.value : 0;
        if (!classify && res.data.value.length > 0) {
          this.fenleId = res.data.value[0].children[0].id;
          this.subFenLe = res.data.value[0].children;
          this.toFuFenle(res.data.value[0].id);
        } else {
          this.fenleId = parseInt(classify);
          this.getActive(this.fenleId);
        }
        this.toFenle(this.fenleId);
      });
    },
    currentChange(val) {
      this.page.currentPage = val;
      this.toFenle(this.fenleId);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: #ffffff;
  background-color: #1850af;
}
.active1 {
  border-bottom: 1px solid #1850af;
  color: #1850af !important;
}
.el-pagination {
  margin-bottom: 63px;
}
.product-mobile {
  box-sizing: border-box;
  font-family: PingFang SC, PingFang SC-Medium;

  .product-titles {
    .product-title {
      margin-top: 14px;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #4d4d4d;
    }
  }

  .menu-scoll {
    width: 348px;
    min-height: 50px;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    //margin-left: 11px;
    padding-left: 5px;
    margin: 0 auto;

    .menu-scoll-item {
      //padding: 4px;
      padding: {
        left: 4px;
        right: 4px;
      }
      margin-right: 30px;
      min-width: 47px;
      min-height: 47px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);

      .menu-scoll-item-title {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
      }

      .menu-scoll-item-doc {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
      }
    }

    .padding-box {
      width: 1px;
      height: 47px;
      margin-left: -25px;
      color: #ffffff;
    }
  }

  .menu-sub-scoll {
    width: 352px;
    height: 35px;
    background: #ffffff;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    margin: 10px auto 0;
    display: flex;
    align-items: center;
    overflow-x: scroll;

    .menu-sub-scoll-item {
      margin-left: 39px;
      min-width: 28px;
      font-size: 14px;
      color: #4d4d4d;
      font-weight: 500;
    }

    .menu-sub-scoll-item:first-child {
      margin-left: 15px;
    }
  }

  .product-list {
    //margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 124px 124px 124px;
    //grid-template-rows: 531px 531px 531px;
    grid-row-gap: 17px;

    .product-list-item {
      //margin-bottom: 17px;
      width: 107px;
      min-height: 123px;
      border-radius: 5px;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      margin: 0 auto;

      .product-list-item-img {
        width: 107px;
        height: 108px;
        background: #a2a2a2;
        border-radius: 5px;
      }

      .product-list-item-title {
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
.product-main-box-right {
  box-sizing: border-box;
  //min-height: 800px;
  //详情
  .detail-info-box {
    //margin-left: 380px;
    //margin-top: 30px;
    //margin-left: 92px;

    .introduce-box {
      margin: 0 auto;
      width: 355px;
      min-height: 15px;
      display: flex;
      margin-bottom: 30px;

      .title {
        //min-width: 110px;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 700;
        text-align: left;
        color: #4d4d4d;
        white-space: nowrap;
        //overflow: hidden;
        text-overflow: ellipsis;
      }

      .detail-text {
        margin-left: 4px;
        //width: 1166px;
        min-height: 15px;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }
  }

  .product-xiangqin-h {
    box-sizing: border-box;
    width: 358px;
    height: 188px;
    margin: 40px auto 0;
    //width: calc(1670px - 270px);
    //margin-top: 14px;
    //margin-left: 11px;
    background: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    .product-xiangqin {
      box-sizing: border-box;
      min-width: 167px;
      height: 188px;
      display: flex;

      .img-box {
        box-sizing: border-box;
        width: 188px;
        height: 188px;
        background: #d1d1d1;
        border-radius: 5px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      }

      .xiangqin-right {
        box-sizing: border-box;
        display: flex;
        margin-left: 7px;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .close:hover {
          cursor: pointer;
        }

        .close {
          position: absolute;
          font-size: 12px;
          right: 8px;
          top: 8px;
        }
        .xiangqin-header {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 155px;

          .xiangqin-name {
            margin-top: 7px;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            color: #666666;
          }

          .xiangqin-jieshao {
            margin-top: 16px;
            width: 127px;
            font-size: 10px;
            font-weight: 400;
            text-align: left;
            color: #666666;
            word-break: break-all;
            display: -webkit-box; /**对象作为伸缩盒子模型展示**/
            -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
            -webkit-line-clamp: 4; /**显示的行数**/
            overflow: hidden; /**隐藏超出的内容**/
          }
        }

        .xiangqin-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          //margin-left: 34px;
          //margin-right: 55px;
          //margin-bottom: 20px;

          .xiangqin-bottom-left {
            .xiangqin-qian {
              .icon {
                font-size: 12px;
              }
              font-size: 20px;
              font-weight: 400;
              text-align: left;
              color: #333333;
            }

            .xiangqin-lianxi {
              font-size: 25px;
              font-weight: 300;
              text-align: left;
              color: #666666;
            }
          }

          .xiangqin-bottom-right {
            width: 60px;
            .buy-img {
              margin: 0 auto;
              width: 40px;
              height: 38px;
              background: #ffffff;
              box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
            }

            .buy-text {
              margin-top: 2px;
              margin-bottom: 6px;
              //width: 141px;
              text-align: center;
              font-size: 10px;
              font-weight: 400;
              color: #4d4d4d;
            }
          }
        }
      }
    }
  }

  .product-xiangqin-size {
    box-sizing: border-box;
    width: 353rem;
    margin-top: 24rem;
    margin-bottom: 24px;
    margin-left: 11rem;
    display: flex;
    flex-wrap: wrap;
    div:hover {
      cursor: pointer;
    }

    div {
      border: 1px solid #1850af;
      border-radius: 4px;
      padding-left: 8px;
      padding-right: 8px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      margin-left: 20px;
      margin-top: 5px;
    }

    // div:first-child {
    //   margin-left: 0px;
    // }
  }

  .card-box {
    display: grid;
    //grid-template-columns: repeat(auto-fill, 337px);
    grid-template-columns: 346px 346px 346px 346px;
    grid-row-gap: 38px;
    //grid-column-gap: 46px;
    flex-wrap: wrap;
    //justify-content: space-around;
    box-sizing: border-box;
    //position: relative;
    width: 1276px;
    //width: calc(1670px - 270px);
    //background-color: #a1e812;
    margin-top: 67px;
    margin-left: 52px;

    .product-card {
      //padding: 6px 9px;
      width: 300px;
      height: 350px;
      background: #fcfcfc;
      border-radius: 5px;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

      .image {
        width: 300px;
        height: 300px;
        background: #b4b4b4;
        border-radius: 5px;
      }
    }

    div {
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        color: #4d4d4d;
      }
    }
  }
}
</style>
